import React from 'react';
import { Container, Navbar, Button, Row, Col, Carousel, Card } from 'react-bootstrap';
import './App.css'; // Make sure to include your CSS file for additional styling
import logo from './425718853_122104660250202782_5243725836867104169_n.jpg';
import about1 from './mitchell-luo-G5i9LQ7sPOw-unsplash.jpg';
import about2 from './theme-photos-Klby0nxseY8-unsplash.jpg';
import about3 from './valentina-giarre-cfNchb3VAI8-unsplash.jpg';
import slideImage1 from './Screenshot 2024-04-02 184729.png';
import slideImage2 from './Screenshot 2024-04-02 184752.png';
import slideImage3 from './Screenshot 2024-04-02 184825.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const projectImages = [
  {
    src: slideImage1,
    alt: "First slide",
    title: "",
    description: ""
  },
  {
    src:slideImage2,
    alt: "Second slide",
    title: "",
    description: ""
  },
  {
    src: slideImage3,
    alt: "Third slide",
    title: "",
    description: ""
  }
  // Add more objects as needed for additional images
];


function App() {
  return (
    <div className="App">
      {/* Navbar section */}
      <Navbar expand="lg" className="navybar justify-content-center">
        <Navbar.Brand href="#home" className="py-3">
          <img src={logo} height='120px' alt="Custom Handy Man Logo" className="d-inline-block align-top" /> {/* Logo will go here */}
        </Navbar.Brand>
      </Navbar>
      
      {/* Main call to action section */}
      <Container fluid className="text-center cta-section">
  <Row className="justify-content-center align-items-center jumbo-row">
    <Col lg={8} md={8}>
      <h1 className="display-1 jumbo-title">Your Home's Helping Hand</h1>
      <p className="lead jumbo-sub">Get professional handyman services for your home.</p>
      <Button className='main-color cta' href="#contact" size="lg">Get a Quote</Button>
    </Col>
  </Row>
</Container>


        {/* About section */}
        <div className='secondary-container'>
        <Container className=" ">
        <Row className="">
          <Col className="text-center">
            <h2 className='title-color'>About Custom Handy Man</h2>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <Card>
              <Card.Img variant="top" src={about3} alt="Experienced Services" />
              <Card.Body>
                <Card.Title className='title-color'>Experienced Handyman Services</Card.Title>
                <Card.Text>
                  Welwyn Garden City Handy man with over 10 years of experience, Custom Handy Man provides a wide
                  range of handyman services including carpentry, plumbing, electrical
                  work, and more.

                
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-3">
            <Card>
              <Card.Img variant="top" src={about1} alt="Reliable and Trustworthy" />
              <Card.Body>
                <Card.Title className='title-color'>Reliable and Trustworthy</Card.Title>
                <Card.Text>
                  We understand the importance of having a handyman you can rely on.
                  That's why our team is always punctual, professional, and respectful
                  of your property.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-3">
            <Card>
              <Card.Img variant="top" src={about2} alt="Affordable Rates" />
              <Card.Body>
                <Card.Title className='title-color'>Affordable Rates</Card.Title>
                <Card.Text>
                  At Custom Handy Man, we believe that everyone deserves access to
                  quality handyman services at a fair price. That's why we offer
                  competitive rates and transparent pricing.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
      {/* Gallery section */}
  {/* Gallery section */}
<Container className="py-5 primary-container">
  <Row>
    <Col xs={12} className="text-center">
      <h2 className='title-color'>Our Latest Projects Gallery</h2>
      <Carousel>
        {projectImages.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100" // 'w-100' makes the image responsive
              src={image.src}
              alt={image.alt}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </Col>
  </Row>
</Container>




      {/* Contact section */}
      <div className='secondary-container'>
      <Container className=" secondary-container contact-container">
        <Row id="contact" className="justify-content-center">
          <Col lg={6} md={8} className="text-center">
          <Col className="text-center">
            <h2 className='title-color'>Contact Us</h2>
          </Col>
            <Card>
              <Card.Body>
                <Card.Text>
                We stay in constant communication with our customers until the job is done. To get a free quote, or if you have questions or special requests, call us on <strong>+44 7701 359341</strong>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container></div>
      {/* Footer section */}
      <footer className="footer bg-light">
        <Container className="text-center">
          <Row>
            <Col>
              <p>Connect with us on social media</p>
              <a href="https://www.facebook.com/profile.php?id=61556083477081" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
            </Col>
          </Row>
        </Container>
      </footer>

    </div>
  );
}

export default App;
